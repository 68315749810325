<template>
    <div>
        <br>
        <br>
        <br>
        <br>
        <el-button @click="print()" class="print-btn" size="large" type="danger">Print</el-button>
        <br>
        <el-card shadow="never" class="border-0">
           
            <div v-html="printed_data" id="printArea"></div>
        </el-card>
    </div> 
</template>
<script>
import axios from 'axios';
export default {
    name: 'PrescriptionPrint',
    data(){
        return {
            printed_data: null,
        }
    },
    created() {
        this.printPrescription();
        
    },
    methods: {
        printPrescription() {
            axios.get(`/api/v2/prescription/print/details/${this.$route.params.id}`)
            .then(res => {
                console.log('res', res);
                this.printed_data = res.data;
                this.print();
               
            })
        },
        print() {
            this.printPage = true;
            setTimeout(() => {
                this.printSetup();
            }, 50);
        },

        printSetup() {
        // Get HTML to print from element
            const prtHtml = document.getElementById('printArea').innerHTML;
            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [
                ...document.querySelectorAll('link[rel="stylesheet"], style'),
            ]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            const WinPrint = window.open(
                '',
                '',
                'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
            );

            WinPrint.document.write(
                `<!DOCTYPE html>
                    <html>
                    <head>
                        <title>Invoice Detail - ${this.$route.params.id}</title>
                        ${stylesHtml}
                    </head>
                    <body>
                        ${prtHtml}
                    </body>
                    </html>
                `);

                WinPrint.document.close();
                WinPrint.focus();
                WinPrint.addEventListener('load', () => {
                    setTimeout(() => {
                    WinPrint.print();
                    WinPrint.close();
                    this.printPage = false;
                    }, 200);
            });
        },
    }
}
</script>
<style>
.print-btn {
    position: absolute;
    top: 80px;
    z-index: 999999999;
}
</style>