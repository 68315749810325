<template>
  <div>
    <el-row justify="space-between" align="middle">
      <el-col :span="12"><h3 class="mb-0">Prescription List</h3></el-col>

      <el-col :sm="24" :md="12" :lg="12" class="text-right" v-if="authUser.user_type == 'executive' || authUser.user_type == 'crm-manager'">
        <el-select @change="getPrescription()" class="m-2" v-model="center_id" placeholder="Select Center"
                   size="large" clearable>
          <el-option
              v-for="item in centers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-col>
    </el-row>
    <div class="text-right">
      <el-button type="danger" @click="exportList">Export</el-button>
    </div>
    <br>
    <el-row :gutter="20" align="middle">
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">Phone number/Name</p>
        <el-input v-model="q" size="large"></el-input>
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">Start date</p>
        <el-date-picker
            v-model="start_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">End date</p>
        <el-date-picker
            v-model="end_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>

      <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
        <p class="demonstration">Followup</p>
        <el-select v-model="has_followup" class="m-2" placeholder="Select" size="large" clearable>
          <el-option value="yes">Yes</el-option>
          <el-option value="no">No</el-option>
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
        <p class="demonstration">Filter type</p>
        <el-select v-model="service_type" class="m-2" placeholder="Select" size="large" clearable>
          <el-option value="Physiotherapy">Physiotherapy</el-option>
          <el-option value="Zero Clubfoot">Zero Clubfoot</el-option>
        </el-select>
      </el-col>
      
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="mt-40">
        <el-button type="primary" size="large" @click="getPrescription" round>Filter</el-button>
        <el-button type="danger" size="large" @click="clear" round>Clear</el-button>
      </el-col>
      <el-col :xs="4" :sm="4" :md="2" :lg="2" :xl="2" class="mt-40">
        <el-button type="warning" size="large" @click="getPrescription" circle>
          <font-awesome-icon icon="fa-solid fa-rotate-right"/>
        </el-button>
      </el-col>
    </el-row>
   
    <br>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="doctors" label="Doctors" width="300">
        <template #default="scope">
          <UserCard :name="scope.row.doctors ? scope.row.doctors.fullname : '-'"
                    :phone_number="scope.row.doctors ? scope.row.doctors.phone_number : ''"
                    :image="scope.row.doctors ? scope.row.doctors.image : ''"></UserCard>
        </template>
      </el-table-column>
      <el-table-column prop="patients" label="Patients" width="300">
        <template #default="scope">
          <UserCard :name="scope.row.patients ? scope.row.patients.fullname: '-'"
                    :phone_number="scope.row.patients ? scope.row.patients.phone_number : ''"
                    :image="scope.row.patients ? scope.row.patients.image : ''"></UserCard>
        </template>
      </el-table-column>
      <el-table-column prop="is_paid" label="Payment Status" width="140">
        <template #default="scope">
          <div>
            <el-tag class="ml-2" v-if="scope.row.is_paid == 'paid'" type="success">Paid</el-tag>
            <!--            <el-tag class="ml-2" v-else-if="scope.row.is_paid == 2" type="info">Partial paid (AP)</el-tag>-->
            <el-tag class="ml-2" v-else type="danger">Unpaid</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="prescription_identifier" label="Prescription identifier">
        <template #default="scope">
          {{ scope.row.prescription_identifier }}
        </template>
      </el-table-column>

      <el-table-column prop="follow_up_date" label="Follow up date"/>
      <el-table-column prop="created_date" label="Create Date"/>
      <el-table-column prop="actions" label="Actions">
        <template #default="scope">
          <el-button type="warning" @click="$router.push('/prescription/preview/'+scope.row.prescription_identifier)" circle><el-icon><Printer /></el-icon></el-button>
          <el-button type="info" @click="downloadPrescription(scope.row.prescription_identifier)" circle><el-icon><Download /></el-icon></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        v-model:current-page="currentPage"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="total_count"
        @current-change="getPrescription"
    />

    <el-dialog
    v-model="printDailog"
    title="Print Prescription"
    width="80%"
  >
    
    <div v-html="printed_data">

    </div>
  </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { CenterMixins } from '@/mixins/CenterMixins';
import UserCard from '../../components/core/UserCard';

export default {
  name: 'PrescriptionList',
  mixins: [CenterMixins],
  components: {
    UserCard,
  },
  data() {
    return {
      tableData: [],
      search: '',
      total_count: 0,
      currentPage: 0,
      per_page: 20,
      start_date: null,
      end_date: null,
      q: '',
      has_followup: '',
      service_type: '',
      printed_data: null,
      printDailog: false,
    };
  },
  created() {
    this.getPrescription();
  },
  methods: {
    getPrescription(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      axios.get(`/api/v1/agent/prescription/list?platform=occ&limit=${this.per_page}&offset=${correctValue || 0}${this.start_date ? `&from_date=${this.start_date}` : ''}${this.end_date ? `&to_date=${this.end_date}` : ''}${this.center_id ? `&institute_id=${this.center_id}` : ''}${this.q ? `&q=${this.q}` : ''}${this.has_followup ? '&has_followup='+this.has_followup : ''}${this.service_type ? '&service_type='+this.service_type : ''}`)
        .then((response) => {
          this.tableData = response.data.data;
          this.total_count = response.data.total_count;
        });
    },
    prescriptionUrl(url) {
      if (url) {
        return `${process.env.VUE_APP_API_URL}/${url}`;
      }
      return '';
    },
    clear() {
      this.start_date = null;
      this.end_date = null;
      this.has_followup = '';
      this.service_type= '';
      this.getPrescription();
    },

    exportList(val){
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      axios.get(`/api/v1/agent/prescription/list/export?platform=occ&limit=${this.per_page}&offset=${correctValue || 0}${this.start_date ? `&from_date=${this.start_date}` : ''}${this.end_date ? `&to_date=${this.end_date}` : ''}${this.center_id ? `&institute_id=${this.center_id}` : ''}${this.q ? `&q=${this.q}` : ''}${this.has_followup ? '&has_followup='+this.has_followup : ''}${this.service_type ? '&service_type='+this.service_type : ''}`,
       { responseType: 'blob' }
      )
      .then((response) => {
       
          const filename = response.headers['content-disposition']
            .split('filename=')[1]
            .split('.')[0];
          const extension = response.headers['content-disposition']
            .split('.')[1]
            .split(';')[0];
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}.${extension}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    printPrescription(identifier) {
      axios.get(`/api/v2/prescription/print/details/${identifier}`,  { responseType: 'blob' })
      .then(res => {
        console.log('res', res);
        this.printed_data = res.data;
        this.printDailog = true;
      })
    },


    downloadPrescription(identifier){
      
      axios.get(`/api/v2/prescription/${identifier}/download`,
       { responseType: 'blob' }
      )
      .then((response) => {
        
          const filename = response.headers['content-disposition']
            .split('filename=')[1]
            .split('.')[0];
          const extension = response.headers['content-disposition']
            .split('.')[1]
            .split(';')[0];
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },

    print() {
      this.printPage = true;
      setTimeout(() => {
        this.printSetup();
      }, 50);
    },

    printSetup() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('printArea').innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title>Invoice Detail - ${this.invoice_details.uid}</title>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener('load', () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
          this.printPage = false;
        }, 200);
      });
    },
    
  },
  title: 'Prescription List',
};
</script>

<style>
.w-100 {
  width: 100%;
}
</style>
